import { useCallback, useState, useMatch } from "react";
import toast from "react-hot-toast";

import { useNavigate } from "react-router-dom";
import { GetUser } from "../../../components/Core/AppState/AppState";
import { CreateTicket } from "../Api/SupportClient";

export default function useCreateTicket(formValues) {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const createTicket = useCallback(async () => {

    try {
      setLoading(true);
   
      const user = GetUser();
      const model = { AccountId: user.AccountId, Properties:[ { Text: "Name", Value: formValues.name }, { Text: "Amount", Value: formValues.amount }]};

      const response = await CreateTicket(model);
      const operationResponse = response.data;

      if(!operationResponse.Success)
      {
        toast.error(operationResponse.Message);
        return;
      }

      toast.success("Saved");
      navigate(`/support/thankyou`);
    } 
    catch (e) 
    {
      toast.error(e.message);
    } 
    finally 
    {
      setLoading(false);
    }

  }, [formValues, navigate]);

  return { createTicket, loading };
}