import { useCallback, useState, useEffect } from "react";
import axios from "axios";
import Config from "../../../config/config";
import { GetIdentity } from "../../../components/Core/AppState/AppState";

export default function useTaskList() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    setData([]);

    //public IdentityRequest Identity { get; set; }
    //public List<FilterModel> Filters { get; set; } = new List<FilterModel>();
    var currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 15); // minus 30 days
    const ticks = currentDate.getTime();
    const identity = GetIdentity();

    const requestBody = {
      Action: "tickets/all",
      Payload: {
        Identity: identity,
        Filters: [
          { Name: "DateFrom", Value: ticks.toString() },
          { Name: "NotInState", Value: "3" },
        ],
      },
    };

    axios.post(Config.ServerUrl, requestBody).then((response) => {
      console.log(response);

      var items = response.data
        .filter((d) => d.RecordState !== 1)
        .sort((a, b) => b.CreatedAt - a.CreatedAt);

      setData(items);
      setLoading(false);
    });
  }, []);

  return { data, setData, loading, error };
}
