import axios from 'axios';
import Config from '../../../config/config';



export function GetTasks(projectId) 
{


  
    const apiPayload = { ProjectId: projectId };
    return Exec("tickets/all", apiPayload);
}

export function TaskEdit(model) 
{
    const apiPayload = { Model: model };
    return Exec("tickets/edit", apiPayload);
}


function Exec(apiAction, apiPayload)
{
  const requestBody = {
    Action: apiAction,
    Payload: apiPayload,
  };
  console.log("api: " + JSON.stringify(requestBody));
  return axios.post(Config.ServerUrl, requestBody);
}
