
import { useCallback, useState, useEffect } from "react";
import axios from 'axios';
import Config from "../../../config/config"


export default function useAgentList() {

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
      setLoading(true);
      setData(null);

    const requestBody = {
      Action: "users/all",
      Payload: { Identity: null },
    };

    axios.post(Config.ServerUrl, requestBody).then(response => {
        console.log(response);
        setData(response.data);
        setLoading(false);
    });
   
  },[])

  return { data, loading, error }
}