
import { useCallback, useState } from "react";

import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { SetUser } from "../../../Core/AppState/AppState"; 
import Config from "../../../../config/config";


export default function useLogin(formValues) {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const login = useCallback(async () => {
    try {
      setLoading(true);

      const requestBody = {
        Action: "auth/login",
        Payload: { Email: formValues.email, Password: formValues.password },
      };

      const response = await axios.post(Config.ServerUrl, requestBody);
      const operationResponse = response.data;
      if(!operationResponse.Success)
      {
        toast.error(operationResponse.Message);
        return;
      }
      const currentUser = operationResponse.User;

      const adminGroup = currentUser.Groups.find(group => group === "admin");
      if(!adminGroup)
      {
        toast.error("You need to have admin permissions to login.");
        return;
      }

      //if(formValues.email !== "qa@gmail.com")
      //{
      //  toast.error("No user found");
      //  return;
      //}
      //const currentUser = { Id:"123", Name:"QA User", AccountId:"123" };
      setLoading(false);
      
      SetUser(currentUser);
     
      navigate("/");

    } catch (e) {
      toast.error(e.message);
    } finally {
      setLoading(false);
    }
  }, [formValues, navigate ]);

  return { login, loading };
}