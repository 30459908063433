

import { BrowserRouter as Router, Route, Routes, useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { Toaster } from "react-hot-toast";


import Auth from "./components/Auth/Auth";
import ContentLayout from "./components/Content/ContentLayout";
import { GetUser } from "./components/Core/AppState/AppState";


function App() {

  const currentUser = GetUser(); 
  const atAuth = document.location.pathname.includes("/auth");
  const navigate = useNavigate();

  useEffect(() => {
    if (!atAuth && !currentUser) {
      navigate("/auth/login");
    }
  }, [navigate, atAuth, currentUser]);


  return (
    <div>
      <div><Toaster/></div>
      <Routes>
        <Route path="/auth/*" element={<Auth />} />
        <Route path="/*" element={<ContentLayout />} /> 
      </Routes>
    </div>
  );
}

export default App;
