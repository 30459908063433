
import { CheckCircleIcon, ChevronRightIcon, EnvelopeIcon } from '@heroicons/react/20/solid'



export default function NotFoundView() {



  return (
    <>Not Found View</>
  );
}