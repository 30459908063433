import axios from 'axios';
import Config from "../../../config/config";




export function CreateTicket(projectId)
{
  const apiPayload =  { ProjectId: projectId };
  return  Exec("projects/get", apiPayload);
}


function Exec(apiAction, apiPayload)
{
  const requestBody = {
    Action: apiAction,
    Payload: apiPayload,
  };
  console.log("api: " + JSON.stringify(requestBody));
  return axios.post(Config.serverUrl, requestBody);
}