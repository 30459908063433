import { CheckCircleIcon, ChevronRightIcon, EnvelopeIcon } from '@heroicons/react/20/solid'


import NotFoundView from './Components/NotFoundView/NotFoundView'
import useAgentList from "./Hooks/useAgentList";

import { useCallback, useState, useEffect } from "react";
import axios from 'axios';
import Config from "../../config/config";
import { GetIdentity } from '../../components/Core/AppState/AppState';
import AgentView from './Components/AgentView';

export default function AgentListWidget() {



  const [items, setItems] = useState([]);

  const reloadCall = () =>{

    var identity = GetIdentity();
    const requestBody = {
        Action: "users/all",
        Payload: { Identity: identity },
    };

    axios.post(Config.ServerUrl, requestBody).then(response => {
        if(!response || !response.data)
        return;

        //const newData = response.data?.filter(item => item.Online);
        const onlineAgents  = response.data?.filter(user => 
          user.Online 
          && ( user.Groups.includes("agent") || user.Groups.includes("user") ));


        console.log(onlineAgents);
        setItems(onlineAgents);
    });

    console.log('reload call');

  };

  useEffect(() => {

    reloadCall();

    // timer 
    const time = 20 * 1000;// 20 sec
    const interval = setInterval(() => reloadCall(), time);
    return () => clearInterval(interval);
  }, []);


  return (
    <>

    <div class="flex flex-row ">
    {!items ? <></> : items?.map((agent) => 
          <AgentView key={agent.Id} agent={agent} />)}


    </div>



    </>
  )
}
