
import { useCallback, useState, useEffect } from "react";
import axios from 'axios';
import Config from "../../../config/config"


export default function useFormList() {
  const [locations, setLocations] = useState(null);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
  
    const items = [
        { Id:"1", Name:"Location 1" },
        { Id:"2", Name:"Location 2" },
        { Id:"3", Name:"Location 3" },
        { Id:"4", Name:"Location 4" },
        { Id:"5", Name:"Location 5" },
        { Id:"6", Name:"Location 6" },
        { Id:"7", Name:"Location 7" },
        { Id:"8", Name:"Location 8" },
        { Id:"9", Name:"Location 9" },
    ];


    setLoading(true);
    setLocations([]);

  const requestBody = {
    Action: "sites/all",
    Payload: { Identity: { FacilityId:"1027"}, Filters:[] },
  };

  axios.post("https://qaqcpro.azurewebsites.net/engine/call", requestBody).then(response => {
      console.log(response);
      setLocations(response.data);
      setLoading(false);
  });


  },[])

  return { locations, loading, error }
}