import React, { useState } from 'react';

import Dashbaord from '../../apps/Dashboard/Dashboard';
import Demo from '../../apps/Demo/Demo';
import Support from '../../apps/Support/Support';
import ThankYou from '../../apps/Support/ThankYou';

import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import AgentList from '../../apps/Agent/AgentList';



import TaskList from '../../apps/Task/TaskList';
import TaskEdit from '../../apps/Task/TaskEdit';

import ItemList from '../../apps/Archive/ItemList';

import { Reset } from '../Core/AppState/AppState';

import { FiUsers, FiHome, FiLogOut, FiArchive } from 'react-icons/fi';


export default function ContentLayout() {

   const navigate = useNavigate();

   const onLogOut = () => {

         // eslint-disable-next-line no-restricted-globals
         var confirmed = confirm("Please confirm to log out?"); 
         if(!confirmed)
            return;
         
         Reset();
         navigate('/');
   };



  return (<>
 <aside id="default-sidebar" class="fixed top-0 left-0 z-40 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0" aria-label="Sidebar">
    <div class="h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800">
       <ul class="space-y-2 font-medium">

         {/*
          <li>
             <a href="/dashboard" class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                <svg aria-hidden="true" class="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path><path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path></svg>
                <span class="ml-3">Dashboard</span>
             </a>
          </li>
         */}
  
          <li>
             <a href="/tasks" class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                <FiHome size={20} />
                <span class="flex-1 ml-3 whitespace-nowrap">Tasks</span>
             </a>
          </li>
    
          <li>
             <a href="/auditors" class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                <FiUsers size={20} />
                <span class="flex-1 ml-3 whitespace-nowrap">Auditors</span>
             </a>
          </li>

    
          <li>
             <a href="/archive" class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                <FiArchive size={20} />
                <span class="flex-1 ml-3 whitespace-nowrap">Archive</span>
             </a>
          </li>
      
         {/*
          <li>
             <a href="/support" class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                <svg aria-hidden="true" class="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path><path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path></svg>
                <span class="flex-1 ml-3 whitespace-nowrap">Support</span>
             </a>
          </li>
         */}

          <li>
             <a href="#" onClick={onLogOut} class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                <FiLogOut size={20} />
                <span class="flex-1 ml-3 whitespace-nowrap">Log Out</span>
             </a>
          </li>
       </ul>
    </div>
 </aside>
 
 <div class="p-4 sm:ml-64">


    <Routes className="h-100">

          {/* ------------ */}
          <Route path="/" element={<TaskList />} />
          <Route path="/dashboard" element={<Dashbaord />} />
          <Route path="/reports" element={<Demo />} />
          
          {/* support */}
          <Route path="/support" element={<Support />} />
          <Route path="/support/thankyou" element={<ThankYou />} />

         
          <Route path="/auditors" element={<AgentList />} />

          {/* tasks */}
          <Route path="/tasks" element={<TaskList />} />
          <Route path="/tasks/edit/:taskId" element={<TaskEdit />} />
          <Route path="/tasks/create" element={<TaskEdit />} />

          {/* archive */}
          <Route path="/archive" element={<ItemList />} />

        </Routes>



 </div>


 </>);
};