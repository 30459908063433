
import { CheckCircleIcon, ChevronRightIcon, EnvelopeIcon } from '@heroicons/react/20/solid'


import agentLogo from "./../Assets/user.png";

export default function TaskRow({ task, openPopup }) {


  var stateColor = "text-black";
  if(task.State.Name === "Pending")
    stateColor = "text-red-600";// red
  if(task.State.Name === "Active")
    stateColor = "text-yellow-600";// orange
  if(task.State.Name === "Completed")
    stateColor = "text-green-600";// red

  var style = "mt-1 text-sm truncate "+stateColor;

  const dateStr = new Date(task.CreatedAt).toISOString().substr(0,10);

  return (

          <li key={task.Id}>
            <div   onClick={openPopup} className="block cursor-pointer hover:bg-gray-50">
              <div className="flex items-center px-4 py-4 sm:px-6">
                <div className="flex min-w-0 flex-1 items-center">
                  <div className="flex-shrink-0">
                  <img
                  src={"/logo.png"}
                  alt="Tasks" className="h-14 w-14 rounded"
                  width={95} height={95} />
                  {/*  <img className="h-14 w-14 rounded" src={job.Logo ?? "logo.png"} alt="" /> */}

                  </div>
                  <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-3 md:gap-3">
                    <div>
                      <p className="truncate text-sm font-medium text-indigo-600">{task.Location?.Name ?? "Undefined"}</p>
                      <p className="flex items-center text-sm text-gray-500">
                        <span className="mt-1 truncate">{task.Form?.Name ?? "Undefined"}</span>
                      </p>
                      <span className={style}>{task.State?.Name ?? "Undefined"}</span>
                    </div>



          
                    <div className="hidden md:block">
                      <div>
                        <p className="text-sm text-gray-900">
                        Date
                        </p>
                        <div className="mt-2 flex items-center text-sm text-gray-500">
                        {dateStr ?? "Unassigned"}
                        </div>
                      </div>
                    </div>
                    
                     
                    <div className="hidden md:block">
                      <div>
                        <p className="text-sm text-gray-900">
                        </p>
                        <div className="mt-2 flex items-center text-sm text-gray-500">
                        <img  src={agentLogo}  alt="Tasks" className="h-9 w-9 mr-2 rounded"
                          width={20} height={20} />{task.Assignee?.Name ?? "Unassigned"}
                        </div>
                      </div>
                    </div>


                  </div>
                </div>
                <div>
                {/*
                <button target="_blank" class="mr-2 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
                    Apply
                </button>
                */}
                </div>
                <div>
                  <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
              </div>
            </div>
          </li>
     
  )
}