
import { useCallback, useState, useMatch } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import Config from "../../../config/config"
import { GetIdentity, GetUser } from "../../../components/Core/AppState/AppState";


export default function useTaskEdit(formValues, agents, forms, locations, states) {


  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);


  const taskEdit = useCallback(async () => {
    try {
      setLoading(true);


      const locationId = formValues.locationId;
      const location = locations?.find(loc => loc.id.toString() === locationId);
  


      const formId =  formValues.formId;
      const form = forms?.find(form => form.Id === formId);


      const assigneeId = formValues.assigneeId;
      const assignee = agents?.find(agent => agent.Id === assigneeId);


      const stateId = formValues.stateId;
      const state = states?.find(state => state.Id === stateId);
  

      const fields = [
        { Name:"zone", Value: formValues.reportZone },
        { Name:"type", Value: formValues.reportType },
        { Name:"operator", Value: formValues.reportOperator },
        { Name:"received", Value: formValues.reportReceived },
        { Name:"dispatched", Value: formValues.reportDispatched }
      ];
      

      const createdAt = Date.now();
      const recordState = 0;


      const identity = GetIdentity();

      // POST request using axios with async/await
      const requestBody = {
        Action: "tickets/edit",
        Payload: {
            Identity: identity,
            Model: 
            {
                Id: null,
                AccountId: identity.AccountId,

                LocationId: locationId,
                Location: location ? { Id: location.id.toString(), Name: location.name, Address: location.address } : null,


                FormId: formId, 
                Form: form ? { Id: form.Id, Name: form.Name } : null,


                AssigneeId: assigneeId, 
                Assignee: assignee ? { Id: assignee.Id, Name: assignee.Name, Fields: assignee.Fields  }: null,


                StateId: stateId,
                State: state ? { Id: state.Id, Name: state.Name }: null,


                Fields: fields,
                

                CreatedAt: createdAt,
                RecordState: recordState
            }
        },
      };

      console.log(requestBody);
      const response = await axios.post(Config.ServerUrl, requestBody);
      console.log(response);

      const operationResponse = response.data;
      if(!operationResponse.Success)
      {
        toast.error(operationResponse.Message);
        return;
      }
      toast.success("Saved");
      navigate(`/tasks`);

    } catch (e) {
      console.log(e);
      toast.error(e.message);
    } finally {

      setLoading(false);
    }
  }, [agents, formValues.assigneeId, formValues.formId, formValues.locationId, formValues.name, forms, locations, navigate, states]);

  return { taskEdit, loading };
}