import { Fragment, useState, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import useAgentList from './Hooks/useAgentList';


export default function TaskEditPopup({ popup, setPopup, action }) {

  const itemDelete = () => {
    action("delete","", popup.item);   
  };

  const onClose = () => {
    setPopup({item: null, open:false });
  };

  const { agents, error } = useAgentList();
  const onlineAgents  = agents?.filter(user => ( user.Groups.includes("agent") || user.Groups.includes("user") ));

  const states = [
    {Id: "1", Name:"Pending"},
    {Id: "2", Name:"In Progress"},
    {Id: "3", Name:"Completed"},
    {Id: "4", Name:"Cancelled"},
  ];
  const task = popup?.item;





  const onChange = (args) =>
  {
    console.log(args.target.value);
  };

  const selectStateRef = useRef(null);
  const selectAssigneeRef = useRef(null);
  const onSave = () => 
  {

    const task = popup.item;
    // update assignee
    var newUserId = selectAssigneeRef.current.value;
    var newUser = onlineAgents.find(p => p.Id === newUserId);
    // update state
    var newStateId = selectStateRef.current.value;
    var newState = states.find(p => p.Id === newStateId);
    // api call
    var newTask = {...task, Assignee: newUser, AssigneeId: newUserId, State: newState, StateId: newStateId };
    action("edit","", newTask);   

  };


  return (
    <Transition.Root show={popup.open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>


        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                
                
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      {popup.item?.Location.Name}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                      {popup.item?.Form.Name}
                      </p>
                    </div>
                  </div>
                </div>



                <div className="sm:col-span-4">
                <label for="assigneeId"  className="block text-sm font-medium leading-6 text-gray-900">Assignee</label>
                <div className="mt-1">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                <select ref={selectAssigneeRef} onChange={onChange} defaultValue={task?.Assignee?.Id}  name="assigneeId" id="assigneeId" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                    <option value="" selected>Choose a assignee</option>
                    {onlineAgents?.map(agent => <option value={agent.Id}>{agent.Name}</option>)}
                </select>
                </div>
                </div>
                </div>



                <div className="sm:col-span-4 mt-2">
                <label for="stateId" className="block text-sm font-medium leading-6 text-gray-900">State</label>
                <div className="mt-1">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                <select ref={selectStateRef} defaultValue={task?.State.Id} name="stateId" id="stateId" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                    {states?.map(state => <option value={state.Id}>{state.Name}</option>)}
                </select>
                </div>
                </div>
                </div>






            <div class="flex flex-row mt-5">
            <button
              type="button"
              onClick={() => itemDelete()}
              className="basis-1/2 mr-1 mt-2 w-full justify-center rounded-md 
              bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm 
              hover:bg-red-500 focus-visible:outline focus-visible:outline-2 
              focus-visible:outline-offset-2 focus-visible:outline-red-600">Delete</button>
            <button
              type="button"
              onClick={() => onSave()}
              className="basis-1/2 ml-1 mt-2 w-full justify-center rounded-md 
              bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm 
              hover:bg-green-500 focus-visible:outline focus-visible:outline-2 
              focus-visible:outline-offset-2 focus-visible:outline-green-600">Save</button>
            </div>



            </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}