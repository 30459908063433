
import { useCallback, useState, useEffect } from "react";
import axios from 'axios';
import Config from "../../../config/config"


export default function useFormList() {
  const [forms, setForms] = useState(null);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
  
    const items = [
        { Id:"1", Name:"RÉPONSE D'ALARME" },
        //{ Id:"2", Name:"INSPECTION DE VÉHICULE" },
        //{ Id:"3", Name:"QUOTIDIEN DE SÉCURITÉ" },
        //{ Id:"4", Name:"CÔNTROL DE QUALITÉ" },
        //{ Id:"5", Name:"VISITE PRÉVENTIVE" },
        //{ Id:"6", Name:"NCR" },
        //{ Id:"7", Name:"RAPPORT INCIDENT" },
        //{ Id:"8", Name:"DEMANDE OUVERTURE" },
        //{ Id:"9", Name:"DEMANDE FERMETURE" },
    ];
    setForms(items);

   
  },[])

  return { forms, loading, error }
}