
import { useCallback, useState, useEffect } from "react";
import axios from 'axios';
import Config from "../../../config/config"
import { GetIdentity } from "../../../components/Core/AppState/AppState";


export default function useItemList({ state }) {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
      setLoading(true);
      setData([]);


      var startDate = state[0].startDate;
      var endDate = state[0].endDate;

      var filters = [];

      
      startDate && filters.push({ Name:"DateFrom", Value: startDate.getTime().toString() });
      
      if(endDate)
      {
        endDate.setHours(23,59,59,999);

        filters.push({ Name:"DateTo", Value: endDate.getTime().toString() });
      }

      console.log(filters);

    //public IdentityRequest Identity { get; set; }
    //public List<FilterModel> Filters { get; set; } = new List<FilterModel>();
    //var currentDate = new Date();
    //currentDate.setDate(currentDate.getDate() - 15); // minus 30 days
    //const ticks = currentDate.getTime();


    const identity = GetIdentity();
    const requestBody = {
      Action: "tickets/all",
      Payload: { Identity: identity, Filters: filters },
    };


    axios.post(Config.ServerUrl, requestBody).then(response => {
  


      console.log(requestBody);
        var items = response.data.filter(d => d.RecordState !== 1).sort((a, b) => b.CreatedAt - a.CreatedAt);
        console.log(items);

        setData(items);
        setLoading(false);
    });
   
  },[ state ])

  return { data, setData, loading, error }
}