
import { useCallback, useState } from "react";

import Cookies from 'js-cookie';

const user_key = "trimax_dispatcher_user";

export function GetUser() 
{
    const currentUser = Cookies.get(user_key); 
    return currentUser;
}

export function GetIdentity() 
{
    const json = Cookies.get(user_key); 
    if(!json)
        return null;
    var currentUser = JSON.parse(json);
    return { UserId: currentUser.Id, AccountId: currentUser.AccountId };
}


export function SetUser(user) 
{
    Cookies.set(user_key, JSON.stringify(user));
}

export function Reset() 
{
    Cookies.set(user_key, null);
}