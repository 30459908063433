
import React from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";


import Login from "./Login/Login";




export default function Auth() {
  const navigate = useNavigate();
  const location = useLocation();

  if (location.pathname === "/") navigate("/projects");

  return (
        <Routes>
              <Route path="login" element={<Login />} />
        </Routes>
  );
}