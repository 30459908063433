
import { CheckCircleIcon, ChevronRightIcon, EnvelopeIcon } from '@heroicons/react/20/solid'


import agentlogo from "./../Assets/user.png";

export default function AgentRow({ agent, openPopup }) {


  return (

          <li key={agent.Id}>
            <div  onClick={openPopup} className="block cursor-pointer hover:bg-gray-50">
              <div className="flex items-center px-4 py-4 sm:px-6">
                <div className="flex min-w-0 flex-1 items-center">
                  <div className="flex-shrink-0">
                  <img
                  src={agentlogo}
                  alt="Tasks" className="h-14 w-14 rounded"
                  width={95} height={95} />
                  <div className={agent.Online ? 'bg-emerald-400 w-2 h-2 rounded' : 'bg-rose-600 w-2 h-2 rounded'} />
                  {/*  <img className="h-14 w-14 rounded" src={job.Logo ?? "logo.png"} alt="" /> */}

                  </div>
                  <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-2">
                    <div>
                      <p className="truncate text-sm font-medium text-indigo-600">{agent.Name}</p>
                      <p className="flex items-center text-sm text-gray-500">
                        <span className="mt-1 truncate">{agent.Email}</span>
                      </p>
                      <span className="mt-1  text-sm text-gray-500 truncate">{agent.Online ? "Online": "Offline"}</span>
                    </div>
          

                    <div className="hidden md:block">
                      <div>
                        <p className="text-sm text-gray-900">
                
                        </p>
                        <div className="mt-2 flex items-center text-sm text-gray-500">
                
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
 
                <div>
                  <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
              </div>
            </div>
          </li>
     
  )
}