
import { useCallback, useState, useEffect } from "react";
import axios from 'axios';
import Config from "../../../config/config"


export default function useAgentList() {
  const [agents, setAgents] = useState(null);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
      setLoading(true);
      setAgents(null);

    const requestBody = {
        Action: "users/all",
        Payload: { Identity: null },
    };

    axios.post(Config.ServerUrl, requestBody).then(response => {
        console.log(response);

        
        setAgents(response.data);
        setLoading(false);
    });
   
  },[])

  return { agents, loading, error }
}